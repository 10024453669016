import React, { useState, useEffect } from 'react';
import '../../AllCss/Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    'https://img.freepik.com/free-photo/close-up-hand-taking-notes_23-2148888827.jpg?size=626&ext=jpg&ga=GA1.1.1182310898.1720517224&semt=ais_hybrid',
    'https://img.freepik.com/premium-photo/woman-sits-book-with-world-map-top_979520-146286.jpg?size=626&ext=jpg&ga=GA1.1.1182310898.1720517224&semt=ais_hybrid',
    'https://img.freepik.com/free-photo/learning-education-ideas-insight-intelligence-study-concept_53876-120116.jpg?size=626&ext=jpg&ga=GA1.1.1182310898.1720517224&semt=ais_hybrid',
    'https://img.freepik.com/premium-photo/online-training-courses-distance-education-internet-studying-online-book-tutorials-elearning-online-education-design-mobile-web-graphics_968529-295589.jpg?size=626&ext=jpg&ga=GA1.1.1182310898.1720517224&semt=ais_hybrid',
    'https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG9ubGluZSUyMGVkdWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D',
    'https://images.unsplash.com/photo-1519389950473-47ba0277781c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fG9ubGluZSUyMGVkdWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D'
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const controlNavigate = () => {
    navigate('/menu');
  };
  
  const controlSignup = () => {
    navigate('/register');
  };

  const slides = [
    'https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/globalLanguagesImg.png',
    'https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/quizQuestionGenerationImg.png'
  ];

  const Slides = [
    {
      title: 'Vernacular Languages to Global Languages',
      subtitle: 'Teach in 75+ Languages',
      image: 'https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/globalLanguagesImg.png',
      description: `BrightClass helps you teach your students in their native language with the support of generating content in 75+ languages with a click elevating the understanding levels of students.`,
      link: '/gen_ai_features/ai_lesson_plan_generator'
    },
    {
      title: 'Question Generation Based on',
      subtitle: 'Bloom’s Taxonomy',
      image: 'https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/quizQuestionGenerationImg.png',
      description: `Teachers can use the questionnaire to improve students’ performance, adhering to the learning objectives specified in the prime pedagogical framework, Bloom’s Taxonomy.`,
      link: '#'
    }
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="Total-content">
      <div className="Topbar_topBarMain__YHiY8" style={{ borderBottom: 'none' }}>
        <div className="Topbar_topBarContainer__ZpjAs">
          <div style={{ cursor: 'pointer' }}>
            <img
              src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/brightclassLogoWithName.svg"
              alt="icon"
              width="156"
            />
          </div>
          <div className="Topbar_navBarRightContainer__rfNZL">
            <div className="Topbar_menuItemsContainer__nKiU5">
              <ul className="Topbar_navItemsContainer__TsHdZ">
                <li>Products</li>
                <li>Pricing</li>
                <li>GenAI Features</li>
                <li>Resources</li>
                <li>Contact Us</li>
              </ul>
            </div>
            <button
              onClick={controlNavigate}
              className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium Topbar_twoD_Button__V_xGm css-79xub"
              tabIndex="0"
              type="button"
            >
              Login
              <span className="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
            <button
              onClick={controlSignup}
              className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium Topbar_twoD_Button_getStart__sxCLK css-79xub"
              tabIndex="0"
              type="button"
            >
              Sign Up Free
              <span className="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
            <div className="Topbar_mobileMenuTabView__w9Bb2">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="MenuIcon"
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>


      <div className="slider-container">
      <img src={images[currentImageIndex]} alt="Sliding images" className="slider-image" />
    </div>


      <div className="section2MobileCard_section2mobileCardStyle__6Jt_p">
      <h4>Content</h4>
      <div className="section2MobileCard_section2mobileCardParaStyle__megFt">
        Create engaging curriculum-centric lesson plans, lessons, and PPTs on any desired topic in less than a minute, including multimedia and quizzes.
      </div>
      <div className="section2MobileCard_section2mobileCardListStyle__zfpBm">
        <div>
          <div className="section2MobileCard_colorDot__u8dpe" style={{ background: '#B1DED1' }}>
            <img
              alt="Vercel img"
              loading="lazy"
              sizes="100vw"
              srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FbrightclassHomePageAIlessonCreationToolIcon.png&w=1080&q=75"
            />
          </div>
          <h5>AI Lesson Plan Generator</h5>
          <div className="section2MobileCard_section2mobileCardListParaStyle__ZU9Z0">
            Simply enter the lesson name, grade, teaching objective, and curriculum, and let AI Generate Lesson Plan for you.
            <a href="/gen_ai_features/ai_lesson_plan_generator" style={{ color: '#fe5c0d' }}>Learn more</a>
          </div>
        </div>
        <div>
          <div className="section2MobileCard_colorDot__u8dpe" style={{ background: '#A9E7F5' }}>
            <img
              alt="Vercel img"
              loading="lazy"
              
              sizes="100vw"
              srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FbrightclassHomePageAIpptGenerationToolIcon.png&w=1080&q=75"
            />
          </div>
          <h5>AI PPT Generation Tool</h5>
          <div className="section2MobileCard_section2mobileCardListParaStyle__ZU9Z0">
            Use BrightClass AI ppt generator to create Impactful PPTs with the most updated material in less than a minute.
            <a href="/gen_ai_features/ai_ppt_generator" style={{ color: '#fe5c0d' }}>Learn more</a>
          </div>
        </div>
        <div>
          <div className="section2MobileCard_colorDot__u8dpe" style={{ background: '#D4D5F5' }}>
            <img
              alt="Vercel img"
              loading="lazy"
              sizes="100vw"
              srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FbrightclassHomePageAIsummarisationToolIcon.png&w=1080&q=75"
            />
          </div>
          <h5>AI Summarisation Tool</h5>
          <div className="section2MobileCard_section2mobileCardListParaStyle__ZU9Z0">
            Teachers can chat with documents or YouTube videos and get key points and summaries in seconds using BrightClass AI video summarizer.
            <a href="/gen_ai_features/ai_video_summarizer" style={{ color: '#fe5c0d' }}>Learn more</a>
          </div>
        </div>
      </div>
    </div>
      <div className="HomePage">
        <section className="homepageV2_section1__pezau">
          <h3>
            A GenAI Teaching Tool For
            <div className="homepageV2_randomTextContainer__ydL32">
              <div>Student Engagement</div>
              <div>Whiteboarding</div>
              <div>Content Preparation</div>
            </div>
          </h3>
          <div className="homepageV2_buttonStyle__ujC5_">
            <div className="ThreeDbuttonTwo_buttonContainer__G4W0U">
              <a href='/register' className="ThreeDbuttonTwo_button2__LtIyQ undefined">Sign Up Free</a>
            </div>
          </div>
          <div className="homepageV2_section1set2__0ZVFj">
            <div className="homepageV2_section1set2Text__5E7LA">
              The Trusted Choice of 10,000+ Premier Schools Worldwide
            </div>
            <div className="CustomSlider_wrapper__bkpRB">
              <div className="CustomSlider_slider__P5SEQ">
                <div className="CustomSlider_slide__4GxB_">
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo1.png" alt="Logo 1"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo2.png" alt="Logo 2"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo3.png" alt="Logo 3"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo4.png" alt="Logo 4"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo5.png" alt="Logo 5"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo6.png" alt="Logo 6"/>
                </div>
                {/* Duplicate set of images for the infinite loop */}
                <div className="CustomSlider_slide__4GxB_">
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo1.png" alt="Logo 1"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo2.png" alt="Logo 2"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo3.png" alt="Logo 3"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo4.png" alt="Logo 4"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo5.png" alt="Logo 5"/>
                  <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/outerBandsLogo6.png" alt="Logo 6"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="homepage">
      <section className="homepageV2_section3Background__FPcfs">
          <section className="homepageV2_section3__lRfat">
            <div className="section5-carousel">
              <div className="slides-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {Slides.map((slide, index) => (
                  <div key={index} className="slide">
                    <div className="section3Card_section3CardStyle__teP_Y">
                      <div className="sectionHeader_textWhite__WfF1N">
                        {slide.title} <br />
                        <span>{slide.subtitle}</span>
                      </div>
                      <div className="section3Card_section3CardBodyStyle__YIUOG">
                        <img
                          alt={slide.title}
                          loading="lazy"
                          src={slide.image}
                        />
                        <p>
                          {slide.description}
                          <a target="_self" style={{ color: '#fe5c0d' }} href={slide.link}>
                            Know More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="arrow arrow-left" onClick={handlePrev}>←</div>
              <div className="arrow arrow-right" onClick={handleNext}>→</div>
              <span className="section5-indicators">
                {Slides.map((_, index) => (
                  <button
                    key={index}
                    className={`section5-indicator ${currentIndex === index ? 'indicator-active' : ''}`}
                    onClick={() => setCurrentIndex(index)}
                  ></button>
                ))}
              </span>
            </div>
          </section>
        </section>
      </div>
      <div className='Home-middle'>
      <section className="homepageV2_multiColorBackground__S6pv5">
      <section className="homepageV2_section7__ApKTY">
        <div className="sectionHeader_textWhite__WfF1N">
          Skyrocket Your Teaching Efficiency
        </div>
        <p className="homepageV2_section7Para__bAXxQ">
          Join the 50,000+ strong BrightClass community for efficient lesson planning, dynamic teaching, and amplified student engagement.
        </p>
        <div className="homepageV2_section7TextContainer__D0rrX">
          <div className="homepageV2_section7TextContainerInner__e3rhI">
            <div className="homepageV2_section7IconContainer__V_eOs">
              <img
                alt="Less Cost"
                loading="lazy"
                width="70"
                height="auto"
                srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FIncressLevelWhiteIcon.png&w=1920&q=75"
                style={{ color: 'transparent', width: '70px', height: 'auto' }}
              />
              <h2>75%</h2>
            </div>
            <p>Less Cost</p>
          </div>
          <div className="homepageV2_section7TextContainerInner__e3rhI">
            <div className="homepageV2_section7IconContainer__V_eOs">
              <img
                alt="Improvement in Efficiency"
                loading="lazy"
                width="70"
                height="auto"
                srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FactiveSandTimerWhiteIcon.png&w=1920&q=75"
                style={{ color: 'transparent', width: '70px', height: 'auto' }}
              />
              <h2>80%</h2>
            </div>
            <p>Improvement in efficiency</p>
          </div>
          <div className="homepageV2_section7TextContainerInner__e3rhI">
            <div className="homepageV2_section7IconContainer__V_eOs">
              <img
                alt="Time Saved"
                loading="lazy"
                width="70"
                height="auto"
                srcSet="https://brightclass.com/_next/image?url=https%3A%2F%2Fbc-website-image-repo.s3.ap-south-1.amazonaws.com%2FactiveSandTimerWhiteIcon.png&w=1920&q=75"
                style={{ color: 'transparent', width: '70px', height: 'auto' }}
              />
              <h2>50%</h2>
            </div>
            <p>Time Saved</p>
          </div>
        </div>
        <div className="homepageV2_section7btn__Myzgm">
        <a href='/register' className="Button-middle"
        style={{ background: 'orangered', color: 'white' }}
        >Sign Up Free<span className="MuiTouchRipple-root css-w0pj6f"></span></a>
        </div>
      </section>
    </section>
      </div>
      <div className='Footer'>
      <div className="Footer_container">
        <section className="Footer_section1">
          <div className="Footer_brand">
            <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/brightclassLogoWithName.svg" alt="Brand Logo" />
            <p>Created with ❤️️️ by the founders of the Global Leader in Visual Intelligence Technology.</p>
            <div className="Footer_mainBrandLogo">
              <img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/cnxBlackLogo.svg" alt="Brand Logo" />
            </div>
          </div>
        </section>
        <section className="Footer_quickLinks">
          <h5>QUICK LINKS</h5>
          <ul>
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
            <li><a href="/features">Quick Start</a></li>
          </ul>
        </section>
        <section className="Footer_businessEnquiry">
          <h5>BUSINESS ENQUIRY</h5>
          <ul>
            <li>
              <div className="Footer_icon"><img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/mobileIcon.svg" alt="Phone Icon" /></div>
              <a href="tel:1800-121-0023">1800-121-0023</a>
            </li>
            <li>
              <div className="Footer_icon"><img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/emailIcon.svg" alt="Email Icon" /></div>
              <a href="mailto:hello@brightclass.com">hello@brightclass.com</a>
            </li>
          </ul>
          <h5>PRODUCT SUPPORT</h5>
          <ul>
            <li>
              <div className="Footer_icon"><img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/mobileIcon.svg" alt="Phone Icon" /></div>
              <a href="tel:1800-121-0023">1800-121-0023</a>
            </li>
            <li>
              <div className="Footer_icon"><img src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/emailIcon.svg" alt="Email Icon" /></div>
              <a href="mailto:support@brightclass.com">support@brightclass.com</a>
            </li>
          </ul>
        </section>
        <section className="Footer_apps">
        <div className="ButtonCard_container">
            <div className="ButtonCard">
              <img 
                src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/fotTeacherIcon.svg" 
                alt="Teacher App Icon" 
                width="27px" 
              />
              <div className="ButtonCard_label">Teacher App</div>
            </div>
            <div className="ButtonCard">
              <img 
                src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/fotStudentIcon.svg" 
                alt="Student App Icon" 
                width="27px" 
              />
              <div className="ButtonCard_label">Student App</div>
            </div>
            <div className="ButtonCard">
              <img 
                src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/fotAdminIcon.svg" 
                alt="Admin App Icon" 
                width="27px" 
              />
              <div className="ButtonCard_label">Admin App</div>
            </div>
            <div className="ButtonCard">
              <img 
                src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/fotParentIcon.svg" 
                alt="Parent App Icon" 
                width="27px" 
              />
              <div className="ButtonCard_label">Parents App</div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
