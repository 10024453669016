import React from 'react';
import '../../AllCss/InsideContent.css';
import Header from './Header';

const InsideContent = ({ isSidebarOpen }) => {
  return (
    <div className={`inside-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Header />
      <div className="jss53">
        <div>
          <div className="jss109">
            <div>
              <div className="jss111">Analytics</div>
              <div className="jss112">Monday, 9th September</div>
            </div>
          </div>
        </div>
        <div className="jss59">
          <div>
            <div className="jss114">
              <div className="jss115">
                <img
                  src="https://cloud.brightclass.com/static/media/calendarIcon.cb256189.svg"
                  alt="calendar"
                />
                <div className="jss118">
                  <h4 className="MuiTypography-root jss116 MuiTypography-h4">
                    Last 7 Days
                  </h4>
                  <h4 className="MuiTypography-root jss117 MuiTypography-h4">
                    2nd September - 9th September
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="jss72">
            <div className="MuiGrid-root jss50 MuiGrid-container MuiGrid-spacing-xs-2">
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-6">
                <div className="MuiPaper-root jss83 MuiPaper-elevation1 MuiPaper-rounded">
                  <div>
                    <p className="MuiTypography-root jss84 MuiTypography-body1">
                      1
                    </p>
                    <p className="MuiTypography-root jss85 MuiTypography-body1">
                      Total Teachers
                    </p>
                    <div>
                      <p className="MuiTypography-root jss86 MuiTypography-body1">
                        <span className="jss88" style={{ color: 'rgb(56, 226, 93)' }}>
                          +1
                        </span>
                        <span className="jss87"> in 7 days</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <svg
                      width="35"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.418 25.6a3.334 3.334 0 0 0 3.334-3.335V3.93A3.334 3.334 0 0 0 31.418.595h-17.57c.584 1.017.9 2.167.9 3.334h16.67v18.336H16.415V25.6h15.003ZM23.083 8.93v3.334H13.081v21.67H9.747V23.933H6.413v10.001H3.08V20.6H.58v-8.335A3.334 3.334 0 0 1 3.912 8.93h19.17ZM11.414 3.929a3.334 3.334 0 1 1-6.668 0 3.334 3.334 0 0 1 6.668 0Z"
                        fill="#1E33F2"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-6">
                <div className="MuiPaper-root jss83 MuiPaper-elevation1 MuiPaper-rounded">
                  <div>
                    <p className="MuiTypography-root jss84 MuiTypography-body1">
                      1
                    </p>
                    <p className="MuiTypography-root jss85 MuiTypography-body1">
                      Total Classrooms
                    </p>
                    <div>
                      <p className="MuiTypography-root jss86 MuiTypography-body1">
                        <span className="jss88" style={{ color: 'rgb(56, 226, 93)' }}>
                          +1
                        </span>
                        <span className="jss87"> in 7 days</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://cloud.brightclass.com/static/media/school.adc808f7.svg"
                      alt="school"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="jss72">
            <div className="jss74">
              <p className="MuiTypography-root MuiTypography-body1">
                <span className="jss73">Statistics</span>
              </p>
            </div>
            <div className="MuiGrid-root jss50 MuiGrid-container MuiGrid-spacing-xs-2">
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-6">
                <div className="MuiPaper-root jss83 MuiPaper-elevation1 MuiPaper-rounded">
                  <div>
                    <p className="MuiTypography-root jss84 MuiTypography-body1">
                      0
                    </p>
                    <p className="MuiTypography-root jss85 MuiTypography-body1">
                      Whiteboards Created
                    </p>
                    <div>
                      <p className="MuiTypography-root jss86 MuiTypography-body1">
                        <span className="jss88" style={{ color: 'rgb(56, 226, 93)' }}>
                          0
                        </span>
                        <span className="jss87"> in 7 days</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <svg
                      width="36"
                      height="37"
                      viewBox="0 0 36 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M30.854 2.75623H6.12966C4.42547 2.75623 3.04395 4.13775 3.04395 5.84194V24.7313C3.04395 26.4354 4.42547 27.817 6.12966 27.817H30.854C32.5582 27.817 33.9397 26.4354 33.9397 24.7313V5.84194C33.9397 4.13775 32.5582 2.75623 30.854 2.75623ZM6.12966 0.699097C3.28934 0.699097 0.986816 3.00163 0.986816 5.84194V24.7313C0.986816 27.5716 3.28935 29.8741 6.12966 29.8741H30.854C33.6943 29.8741 35.9968 27.5716 35.9968 24.7313V5.84194C35.9968 3.00162 33.6943 0.699097 30.854 0.699097H6.12966Z"
                        fill="#1E33F2"
                      ></path>
                      <path
                        d="M9.46924 35.2404C9.46924 34.4347 10.1223 33.7816 10.928 33.7816H25.5155C26.3211 33.7816 26.9742 34.4347 26.9742 35.2404C26.9742 36.046 26.3211 36.6991 25.5155 36.6991H10.928C10.1223 36.6991 9.46924 36.046 9.46924 35.2404Z"
                        fill="#1E33F2"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.124 7.49448L16.0966 18.5219C15.9796 18.6387 15.9141 18.7971 15.9141 18.9624V22.6382C15.9141 22.9823 16.193 23.2612 16.5371 23.2612H20.2129C20.3781 23.2612 20.5365 23.1956 20.6534 23.0787L31.6808 12.0513C31.9241 11.808 31.9241 11.3899 31.6808 11.1466L28.6342 8.09988C28.3054 7.77139 27.7499 7.77139 27.124 7.49448Z"
                        fill="#1E33F2"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-6">
                <div className="MuiPaper-root jss83 MuiPaper-elevation1 MuiPaper-rounded">
                  <div>
                    <p className="MuiTypography-root jss84 MuiTypography-body1">
                      2
                    </p>
                    <p className="MuiTypography-root jss85 MuiTypography-body1">
                      Total Users
                    </p>
                    <div>
                      <p className="MuiTypography-root jss86 MuiTypography-body1">
                        <span className="jss88" style={{ color: 'rgb(56, 226, 93)' }}>
                          +2
                        </span>
                        <span className="jss87"> in 7 days</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <svg
                      width="35"
                      height="33"
                      viewBox="0 0 35 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.551 10.2726L15.371 4.89015C14.8765 4.34455 14.081 4.07702 13.4885 4.61265L9.70399 7.33907C9.04862 7.86874 8.75416 8.60676 8.75416 9.44355V18.5056C8.75416 19.3377 9.05115 20.0707 9.70535 20.6004L13.4998 23.1878C14.0942 23.7344 14.9182 23.6988 15.371 23.1658L20.5561 17.7827C21.0533 17.2305 21.3478 16.4935 21.3478 15.6533V8.63746C21.3478 7.80467 21.0554 7.06819 20.551 6.5226L20.551 10.2726ZM19.7066 10.7455L15.488 6.52824C15.0921 6.16657 14.3705 6.2292 14.2238 6.74082L14.221 9.42915C14.221 9.67856 14.4427 9.9002 14.6922 9.9002C14.7253 9.9002 14.7582 9.89141 14.7904 9.87409C15.2377 9.66083 15.8636 9.78571 16.2269 10.0983L19.6583 13.0932C19.9528 13.3226 19.7066 13.6113 19.7066 13.8801V11.5069C19.7066 11.423 19.7066 11.3381 19.7066 11.2562V10.7455Z"
                        fill="#1E33F2"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="MuiGrid-root jss50 MuiGrid-container MuiGrid-spacing-xs-2">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12">
              <div className="MuiPaper-root jss83 MuiPaper-elevation1 MuiPaper-rounded">
                <div className="MuiTypography-root jss84 MuiTypography-body1">
                  0
                </div>
                <p className="MuiTypography-root jss85 MuiTypography-body1">
                  You are not eligible for certifications.
                </p>
                <div>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.375 0.4375C8.64345 0.4375 1.875 7.32841 1.875 15.625C1.875 23.9216 8.64345 30.8125 17.375 30.8125C26.1065 30.8125 32.875 23.9216 32.875 15.625C32.875 7.32841 26.1065 0.4375 17.375 0.4375ZM17.375 27.3125C10.2544 27.3125 4.125 21.1832 4.125 15.625C4.125 10.0668 10.2544 3.9375 17.375 3.9375C24.4956 3.9375 30.625 10.0668 30.625 15.625C30.625 21.1832 24.4956 27.3125 17.375 27.3125ZM17.375 18.375C16.6316 18.375 15.9375 17.6809 15.9375 16.9375C15.9375 16.1941 16.6316 15.5 17.375 15.5C18.1184 15.5 18.8125 16.1941 18.8125 16.9375C18.8125 17.6809 18.1184 18.375 17.375 18.375ZM17.375 9.0625C16.4201 9.0625 15.625 9.8576 15.625 10.8125C15.625 11.7674 16.4201 12.5625 17.375 12.5625C18.3299 12.5625 19.125 11.7674 19.125 10.8125C19.125 9.8576 18.3299 9.0625 17.375 9.0625Z"
                      fill="#1E33F2"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsideContent;
