import React from 'react';
import '../../AllCss/Menu.css';
import { Link, useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();

  const handleButtonClick = (role) => {
    // Navigate to the login page with a role parameter
    navigate(`/login?role=${role}`);
  };

  return (
    <div className='Whole-Content'>
      <div className='Heading'>
        <h4>Welcome back to LearningClass!</h4>
        <p>Choose an account type to proceed to sign in</p>
      </div>
      <div className="role-selection">
        
        {/* Student Account */}
        <div 
          className="button-container" 
          style={{ background: 'rgb(237, 103, 46)' }}
          onClick={() => handleButtonClick('student')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/studentButtonImg.png"
            alt="Student Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Student Account</div>
              <div className="button-label2">I'm a student</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>

        {/* Educator Account */}
        <div 
          className="button-container" 
          style={{ background: 'rgb(242, 164, 80)' }}
          onClick={() => handleButtonClick('educator')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/teacherButtonImg.png"
            alt="Educator Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Educator Account</div>
              <div className="button-label2">I'm an educator</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>

        {/* Administrator Account */}
        <div 
          className="button-container" 
          style={{ background: 'rgb(246, 197, 98)' }}
          onClick={() => handleButtonClick('admin')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/adminButtonImg.png"
            alt="Administrator Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Administrator Account</div>
              <div className="button-label2">I'm an admin / IT specialist</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>

        {/* Principal Account */}
        <div 
          className="button-container" 
          style={{ background: 'rgb(246, 197, 98)' }}
          onClick={() => handleButtonClick('principal')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/principalButtonImg.png"
            alt="Principal Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Principal Account</div>
              <div className="button-label2">I'm a principal</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>

        {/* Parent Account */}
        <div 
          className="button-container" 
          style={{ background: 'rgb(249, 218, 114)' }}
          onClick={() => handleButtonClick('parent')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/parentButtonImg.png"
            alt="Parent Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Parent Account</div>
              <div className="button-label2">I'm a family member</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>
        
        <div className="jss10">
          Don't have an account?{' '}
          <a className="jss11" href="/register" target="_blank" rel="noopener noreferrer">
            Sign up now
          </a>
        </div>
        
        <section className="LoginRoleSelect_footerSection__1tyI5">
          By signing in you agree to our{' '}
          <a href="https://learningclass.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          ,{' '}
          <a href="https://learningclass.com/terms-of-service" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          , and{' '}
          <Link to="#" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </Link>
          . ©LearningClass. All Rights Reserved.
        </section>
      </div>
    </div>
  );
};

export default Menu;
