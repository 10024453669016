import React, { useState } from 'react';
import Header from './Header';
import '../../AllCss/Sidebar.css'; // Import the external CSS file

const Sidebar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Header />
        
      {/* Button to open the sidebar when it's closed */}
      {!isSidebarOpen && (
        <div className="sidebar_openBtn" onClick={toggleSidebar}>
          <svg
            className="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M3 12h18v2H3z" />
          </svg>
        </div>
      )}

      <div className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
        <div className="sidebar_sideBarMain">
          <div className="sidebar_leftTopBox">
            <div className="institute-name">My Institute</div>
            <div className="sidebar_closeSidebarBtn" onClick={toggleSidebar}>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </div>
          </div>

          <div className="sidebar_NavBarGrid">
            <div className="sidebar_navItem">
              <svg width="20" height="20" fill="#ED672E">
                {/* SVG Path */}
              </svg>
              <span>Classrooms</span>
            </div>
            <div className="sidebar_navItem">
              <svg width="20" height="20" fill="rgba(0, 0, 0, 0.38)">
                {/* SVG Path */}
              </svg>
              <span>Teachers</span>
            </div>
            <div className="sidebar_navItem">
              <svg width="20" height="20" fill="rgba(0, 0, 0, 0.38)">
                {/* SVG Path */}
              </svg>
              <span>Administrator</span>
            </div>
            <div className="sidebar_navItem">
              <svg width="20" height="20" fill="rgba(0, 0, 0, 0.38)">
                {/* SVG Path */}
              </svg>
              <span>License & Billing</span>
            </div>
          </div>

          <div className="sidebar_teachBtn">Switch to Teach App</div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
