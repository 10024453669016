import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import '../../AllCss/Header.css'; // Import the CSS file

const Header = ({ username }) => {
  return (
    <div>
      <nav className="header_bg">
        <div className="container">
          <div className="header-left">
            <div className="sidebar_burgerButton__2HNLO">
              <Link to="/sidebar">
                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <div className="header-middle">
            <h1>WELCOME</h1>
          </div>
          <div className="notification">
            {/* Inline SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: 'rgba(0, 0, 0, 1)' }}
              className="notification-icon"
            >
              <path d="M12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z" />
            </svg>
            <span className="badge">{/* Notification count goes here */}</span>
          </div>
          <div className="header-right">
            <div className="dropdown">
              <button className="dropbtn">
                {/* Inline SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ fill: 'rgba(0, 0, 0, 1)' }}
                  className="profile-icon"
                >
                  <path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" />
                </svg>
                {username || 'My Profile'}
              </button>
              <div className="dropdown-content">
                <Link to="/profile">
                  {/* Inline SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: 'rgba(0, 0, 0, 1)' }}
                    className="profile-icon"
                  >
                    <path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-6 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM19 15H9v-.25C9 12.901 11.254 11 14 11s5 1.901 5 3.75V15z"></path>
                    <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path>
                  </svg>
                  My Profile
                </Link>
                <button className="dropdown-logout-btn">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
