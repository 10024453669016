import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../AllCss/Register.css";

const Register = () => {
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [Error, setError] = useState("");
    const [Success, setSuccess] = useState("");

    const navigate = useNavigate();
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setMobileNumber("");
        setEmail("");
        setPassword("");
        setError("");
        setSuccess("");
    };

    useEffect(() => {
        clearForm(); // Ensure form is cleared on mount
    }, []);

    const controlSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        if (!FirstName || !LastName || !MobileNumber || !Email || !Password) {
            setError("All fields are required");
            return;
        }

        const formData = {
            firstName: FirstName,
            lastName: LastName,
            mobileNumber: MobileNumber,
            email: Email,
            password: Password,
        };

        try {
            const response = await fetch('http://localhost:5000/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccess("Registration successful");
                console.log("User registered: ", data);
                clearForm();
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                const message = await response.text();
                setError(message);
            }
        } catch (err) {
            console.error("Error:", err);
            setError("Something went wrong. Please try again.");
        }
    };

    return (
        <div className='register'>
            <div className='register-left'>
            <div className="register-backgroundimg"
          style={{
            backgroundImage: 'url("https://as1.ftcdn.net/v2/jpg/04/62/53/34/1000_F_462533410_e5vMFOmxBGFkB7wDWQDEWyYXDNvvNwqn.jpg")',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        >
        </div>
            </div >
            <div className='register-right'>
            <form className='register-width'>
                <h1>Register</h1>
                {Error && <p style={{ color: 'red' }} className='error-message'>{Error}</p>}
                {Success && <p style={{ color: 'blue' }} className='success-message'>{Success}</p>}
                <div className='label-box'>
                    <label htmlFor='FirstName'>First Name</label>
                    <input
                        name='FirstName'
                        value={FirstName}
                        type='text'
                        onChange={(e) => setFirstName(e.target.value)}
                        id='FirstName'
                        autoComplete="off" 
                        required
                    />
                </div>

                <div className='label-box'>
                    <label htmlFor='LastName'>Last Name</label>
                    <input
                        name='LastName'
                        value={LastName}
                        type='text'
                        onChange={(e) => setLastName(e.target.value)}
                        id='LastName'
                        autoComplete="off"  
                        required
                    />
                </div>

                <div className='label-box'>
                    <label htmlFor='MobileNumber'>Mobile Number</label>
                    <input
                        name='MobileNumber'
                        value={MobileNumber}
                        type='text'
                        onChange={(e) => setMobileNumber(e.target.value)}
                        id='MobileNumber'
                        autoComplete="off"  
                        required
                    />
                </div>

                <div className='label-box'>
                    <label htmlFor='Email'>Email</label>
                    <input
                        name='Email'
                        value={Email}
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        id='Email'
                        autoComplete="off"  
                        required
                    />
                </div>

                <div className='label-box'>
                    <label htmlFor='Password'>Password</label>
                    <input
                        name='Password'
                        value={Password}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        id='Password'
                        autoComplete="new-password" // Suggests browsers not to autofill
                        required
                    />
                    <svg
                        onClick={togglePasswordVisibility}
                        viewBox="0 0 576 512"
                        height="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                        />
                    </svg>
                </div>

                <div className='register-remember'>
                    <label>
                        <input type="checkbox" required />I have read and accepted the privacy policy and allow to collect and store the data I submit through this form.*
                    </label>
                </div>

                <button onClick={controlSubmit} type='submit' className='register-btn'>
                    Register
                </button>

                <div className='register-link'>
                    <a href="/passwordreset">Lost Your Password?</a>
                </div>

                <div className='register-button-wrapper'>
                    <div className='register-text'>
                        <span>Already have an account?</span>
                    </div>
                    <a href='/login' className="register-btn1">
                        Log In
                    </a>
                </div>
            </form>
            </div>
        </div>
    );
};

export default Register;
