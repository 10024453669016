import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Login from '../Components/Registration/Login';
import Register from '../Components/Registration/Register';
import Dashboard from '../Components/Dashboard/dashboard';
import PasswordReset from '../Components/Registration/PasswordReset';
import Menu from '../Components/Dashboard/Menu';
import InsideContent from '../Components/Panel/InsideContent';
import Sidebar from '../Components/Panel/Sidebar';
//import PrivateRoute from './PrivateRoute';

const RoutesConfig = () => {
  return (
    <div>
        <BrowserRouter>
        <Routes>
            <Route exact path ="/" element ={<Dashboard/>}/>
            <Route path ="/login" element ={<Login/>} />
            <Route path ="/register" element ={<Register/>} />
            <Route exact path ="/dashboard" element ={<Dashboard/>}/>
            <Route path ="/menu" element ={<Menu/>} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path ="/insidecontent" element ={<InsideContent/>} />
            <Route path='/sidebar' element={<Sidebar/>}/>
        </Routes>
        </BrowserRouter>
    </div>
  )
}

export default RoutesConfig