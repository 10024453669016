import React from 'react'
import '../../AllCss/PasswordReset.css'

const PasswordReset = () => {
  return (
    <div className='Container'>
        <div className='header-container'>
        <header>
            <h3>Password Reset</h3>
        </header>
        </div>
        <div className='reset-form'>
            <p>Lost your password? Please enter your username or email addresss. You will receive a link to create a new password via email.</p>
        </div>
        <form1>
            <label for = "username_email">
            Email Address
            </label>
            <div>
                <input
                name='username_email'
                type='text'
                id='username_email'
                required
                />
            </div>
            <input
            type='submit'
            name='submit_password_recovery'
            className='button'
            value={"Reset Password"}
            />
        </form1>
        <ul className='links'>
            <li>
                Already have an account?
                <a href='/login'> Sign In {'>>'} </a>
            </li>
            <li>
                Don't have an account?
                <a href = '/register'> Signup Now {'>>'}</a>
            </li>
        </ul>
    </div>
  )
}

export default PasswordReset;