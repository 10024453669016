import React, { useState, useEffect } from 'react';
import '../../AllCss/Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [emailaddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const controlSubmit = async (e) => {
    e.preventDefault();

    if (!emailaddress || !password) {
      setError("Email Address and Password are required");
      return;
    }

    setError("");

    try {
      const response = await fetch('http://localhost:5000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailaddress, password }),
      });

      if (response.ok) {
        // Successful login
        navigate('/insidecontent');
        setEmailAddress("");
        setPassword("");
      } else {
        const errorText = await response.text();
        setError(errorText);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('An unexpected error occurred');
    }
  };

  useEffect(() => {
    setEmailAddress("");
    setPassword("");
  }, []);

  return (
    <div className='login-container'>
      <div className='login-left'>
        <div className="AuthPanelNew_leftSection__2-lRy"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1460467820054-c87ab43e9b59?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d2VsY29tZXxlbnwwfHwwfHx8MA%3D%3D")',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        >
        </div>
      </div>

      <div className='login-right'>
        <form onSubmit={controlSubmit}>
          <h2>Login</h2>
          {error && <p style={{ color: 'red' }} className='error-message'>{error}</p>}
          <div className='input-box'>
            <label htmlFor='emailaddress'>Email Address</label>
            <input
              name='emailaddress'
              value={emailaddress}
              type='text'
              onChange={(e) => setEmailAddress(e.target.value)}
              id='emailaddress'
              required
              autoComplete='off'
            />
          </div>
          <div className='input-box'>
            <label htmlFor='password'>Password</label>
            <input
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              id='password'
              required
              autoComplete='new-password'
            />
            <svg
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer' }}
              viewBox="0 0 576 512"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
            </svg>
          </div>
          <div className='login-remember'>
            <label>
              <input type="checkbox" /> Remember Me
            </label>
          </div>
          <button type='submit' className='login-btn'>
            Log In
          </button>
          <div className='login-register-link'>
            <a href="/passwordreset">Lost Your Password?</a>
          </div>
          <div className='login-register-button-wrapper'>
            <div className='login-register-text'>
              <span> Don't have an account?</span>
            </div>
            <a href='/register' className='login-register-btn'>
              Sign Up. It's free.
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
